import React from 'react';
import _ from "lodash";
import {Link, safePrefix} from "../utils";

const TeamList = ({posts}) => {
    return (
        <div className="post-feed">
            {_.map(posts, (post, post_idx) => (
                <article key={post_idx} className="post post-card">
                    <div className="post-card-inside">
                        {_.get(post, "frontmatter.thumb_img_path") && (
                            <Link
                                className="post-card-thumbnail"
                                to={safePrefix(_.get(post, "url"))}
                            >
                                <img
                                    className="thumbnail"
                                    src={safePrefix(
                                        _.get(post, "frontmatter.thumb_img_path")
                                    )}
                                    alt={_.get(post, "frontmatter.title")}
                                    loading="lazy"
                                />
                            </Link>
                        )}
                        <div className="post-card-content">
                            <header className="post-header">
                                <h2 className="post-title">
                                    <Link
                                        to={safePrefix(_.get(post, "url"))}
                                        rel="bookmark"
                                    >
                                        {_.get(post, "frontmatter.title")}
                                    </Link>
                                </h2>
                            </header>
                            <div className="post-excerpt">
                                <p>{_.get(post, "frontmatter.job_position")}</p>
                            </div>
                        </div>
                    </div>
                </article>
            ))}
        </div>
    );
};

export default TeamList;
