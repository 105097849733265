import React from "react";
import _ from "lodash";
import styled from 'styled-components/macro';

import { Layout } from "../components/index";
import { getPages, htmlToReact, safePrefix } from "../utils";
import TeamList from "../components/TeamList";
import Helmet from "react-helmet";

export default class Team extends React.Component {
  render() {
    let display_posts = _.orderBy(
      getPages(this.props.pageContext.pages, "/zespol").filter(
        page => page.name !== "index"
      ),
      "frontmatter.title",
      "asc"
    );
    if (!display_posts) {
      return null;
    }
    const workers = display_posts.filter(
      post => !_.get(post, "frontmatter.collaborator")
    );
    const collaborators = display_posts.filter(post =>
      _.get(post, "frontmatter.collaborator")
    );

    return (
      <Layout {...this.props}>
        <Helmet>
          <title>
            {_.get(this.props, "pageContext.frontmatter.title") &&
              _.get(this.props, "pageContext.frontmatter.title") + " - "}
            {_.get(this.props, "pageContext.site.siteMetadata.title")}
          </title>
        </Helmet>
        <div className="outer">
          <div className="inner-medium">
            <article className="post page post-full">
              <header className="post-header">
                <h1 className="post-title">
                  {_.get(this.props, "pageContext.frontmatter.title")}
                </h1>
              </header>
              {_.get(this.props, "pageContext.frontmatter.img_path") && (
                <div className="post-thumbnail">
                  <img
                    src={safePrefix(
                      _.get(this.props, "pageContext.frontmatter.img_path")
                    )}
                    alt={_.get(this.props, "pageContext.frontmatter.title")}
                  />
                </div>
              )}
              {_.get(this.props, "pageContext.frontmatter.subtitle") && (
                <div className="post-subtitle">
                  {htmlToReact(
                    _.get(this.props, "pageContext.frontmatter.subtitle")
                  )}
                </div>
              )}
              <div className="post-content">
                {htmlToReact(_.get(this.props, "pageContext.html"))}
              </div>
            </article>
          </div>
          <SpacedDiv className="inner">
            <TeamList posts={workers} />
          </SpacedDiv>
          <div className="inner-medium">
            <div className="post page post-full">
              <div className="post-header">
                <h3 className="post-title">Osoby z którymi współpracujemy</h3>
              </div>
              <div className="post-subtitle">
                Psycholodzy, psychoterapeuci i trenerzy z którymi współpracujemy
              </div>
            </div>
          </div>

          <div className="inner">
            <TeamList posts={collaborators} />
          </div>
        </div>
      </Layout>
    );
  }
}

const SpacedDiv = styled.div`
  margin-bottom: 6rem;
`;
